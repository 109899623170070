<template>
  <div>
    <div
      class="font-weight-bold d-none d-sm-inline"
      @click="reserve('desktop', clubConfig)"
    >
      <slot></slot>
    </div>
    <div
      class="font-weight-bold d-inline-xs d-sm-none"
      @click="reserve('mobile', clubConfig)"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

const BASE_MODAL = {
  backdrop: true,
  showCloseButton: true,
  showDenyButton: true,
  showConfirmButton: true,
  confirmButtonText: 'WhatsApp',
  denyButtonText: 'SMS',
  width: 550,
  imageHeight: 250,
  confirmButtonClass: 'btn-block',
  denyButtonClass: 'btn-block',
  confirmButtonColor: 'black',
  denyButtonColor: 'black',
  imageAlt: 'Custom image',
  background: '#1a1818',
};

export default {
  name: 'RsvpMiniParty',
  props: {
    klass: {
      type: String,
      default: 'btn-sm'
    },
    clubConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    niceDate: {
      type: String,
    },
    miniEvent: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    smsMessage() {
      return this.SMS_MESSAGE_CLUB_SPECIFIC.replace('{{date}}', this.miniEvent.metadata.date)
        .replace('{{time}}', this.miniEvent.metadata.start_time)
        .replace('{{club}}', this.clubConfig.name)
        .replace('{{event}}', encodeURIComponent(this.miniEvent.title.split("@")[0]));
    },
  },
  methods: {
    reserve(device, club) {
      if (device === 'desktop') {
        this.rsvpDialog(club, 'desktop');
      } else {
        this.rsvpDialog(club, 'mobile');
      }
    },
    rsvpDialog(club, device) {
      let options = Object.assign({}, BASE_MODAL, {
        title: '',
        html: this.getHtml(),
        footer: this.mailFooter(),
        background: club.color,
        confirmButtonColor: club.secondaryColor || BASE_MODAL.confirmButtonColor,
        denyButtonColor: club.secondaryColor || BASE_MODAL.denyButtonColor,
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          if (device === 'desktop') {
            this.showQR(club.name, this.waQR);
          } else {
            open(`https://wa.me/${this.PHONE_NUMBER}?text=${this.smsMessage}`);
          }
          this.onFreeConciergeClick(device, club);
        } else if (result.isDenied) {
          if (device === 'desktop') {
            this.showQR(club.name, this.smsQR);
          } else {
            open(`sms:+${this.PHONE_NUMBER}&body=${this.smsMessage}`);
          }
          this.onFreeConciergeClick(device, club);
        }
      });
    },
    showQR(club, qrImageUrl) {
      let options = Object.assign({}, BASE_MODAL, {
        html: `<p>Scan the QR and send us a message with your inquiry. We'll be glad to help.</p>`,
        imageWidth: 270,
        imageHeight: 270,
        showDenyButton: false,
        showConfirmButton: false,
        footer: '<small>We operate from 9AM - 10PM / Answer within 15 mins</small>',
        imageUrl: qrImageUrl,
        imageAlt: 'QR for Contact',
      });
      Swal.fire(options);
    },
    onFreeConciergeClick(channel, club) {
      let value = channel === 'desktop' ? 20 : 30;
      let eventSlug =this.miniEvent.slug;
      window.ga('send', 'event', 'reservations', 'Clubs: Info & Reservations', eventSlug, value);
      this.$rollbar.info('Mini Party Info: ' + this.miniEvent.slug, { name: encodeURIComponent(this.miniEvent.title.split("@")[0]) });
    },
    getHtml() {
      return `<div class="text-left">
                <h2 class="tulum-party-title-alt" style="margin-bottom: 0px;font-size: 1.6rem">${this.miniEvent.title.split("@")[0]}</h2>
                <small>${this.niceDate}</small>
                <hr>
                <div >${this.miniEvent.content} </div>
                <hr>
                </div>
                <div class="text-center"
                >
                <small>RESERVE NOW</small>

</div>
`;
    },
    mailto() {
      return `mailto:bookings+${this.miniEvent.title.toLowerCase().replaceAll("@", '').replaceAll("|", '').replaceAll(/\s+/g, '-') || ''}@tulum.party`;
    },
    mailFooter() {
      return `<div class="text-center">
                <a href="${this.mailto()}" class=" text-center">via email</a>
                <br>
                <small>${this.clubConfig.name}</small>
              </div>`;
    },
  },
  data() {
    return {
      PHONE_NUMBER: 524421867547,
      waQR: 'https://imgix.cosmicjs.com/c3b60240-f712-11ec-bf2b-e93971fa55b1-image.png',
      smsQR: 'https://imgix.cosmicjs.com/140a0b30-f22f-11ec-a2eb-c1653f3f9199-telegram-cloud-photo-size-1-5014946017338042974-x.jpg',
      PERSUASIVE_TEXT_CLUBS: [
        "Unlock The Magic - Book Now!",
        "Reserve Now, Party Later!"
      ],
      SMS_MESSAGE_CLUB_SPECIFIC: 'RSVP:%20{{event}}%0aLOCATION:%20{{club}}%0a--------%0aFULL_NAME:%20%0aDATE:%20{{date}}%0aARRIVAL_TIME:%20{{time}}%0aGROUP_SIZE:%20',
    };
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: black !important;
}

button {
  color: black !important;
}
</style>
